import { template as template_2605ef1b4be8415886dacdda0c91df34 } from "@ember/template-compiler";
const FKControlMenuContainer = template_2605ef1b4be8415886dacdda0c91df34(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
