import { template as template_7260d327ca8e4f88b4e34613d125f5fb } from "@ember/template-compiler";
import concatClass from "discourse/helpers/concat-class";
const FKSection = template_7260d327ca8e4f88b4e34613d125f5fb(`
  <div class={{concatClass "form-kit__section" @class}} ...attributes>
    {{#if @title}}
      <h2 class="form-kit__section-title">{{@title}}</h2>
    {{/if}}

    {{#if @subtitle}}
      <span class="form-kit__section-subtitle">{{@subtitle}}</span>
    {{/if}}

    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKSection;
