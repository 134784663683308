import { template as template_9664a5423b0c44759acaf83b339a2277 } from "@ember/template-compiler";
const FKText = template_9664a5423b0c44759acaf83b339a2277(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
